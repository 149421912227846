import boxSavorRR from "../../assets/images/box-savor-brand-4.png";
import axios from "axios";
import Modules from "../modules/Modules";
import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import httpClient from "../../services/httpClient";
import Progressbar from "../Progressbar";
export const modulesQuery = graphql`
  {
    allContentfulModules(sort: { fields: moduleId, order: ASC }) {
      nodes {
        image {
          url
        }
        imageHeader {
          url
        }
        video {
          url
        }
        childContentfulModulesDescriptionTextNode {
          description
        }
        questions {
          title
          choices {
            answer
            isCorrect
          }
        }
        response {
          header
          text {
            textBody
          }
        }
        digitalAssets {
          file {
            url
          }
        }
        name
        module
        moduleId
      }
    }
  }
`;

const MainPage = () => {
  const [grades, setGrades] = useState([]);
  const [completedPercentage, setCompletedPercentage] = useState(0);
  const module = useStaticQuery(modulesQuery);
  useEffect(() => {
    const getModuleGrades = async () => {
      const response = await httpClient("/module_grades");
      if (response?.status === 200) {
        setGrades(response.data.result.userGrades);
      }
    };
    getModuleGrades();
  }, []);

  useEffect(() => {
    const totalModules = module.allContentfulModules.nodes.length - 1;
    const completedModules = grades.filter((grade) => grade.approved).length;
    const percentage = (completedModules / totalModules) * 100;
    console.log(grades, totalModules, percentage);
    setCompletedPercentage(percentage);
  },[grades]);

  return (
    <div className="flex flex-col">
      <div className="md:flex md:flex-row ml-4 mr-4">
        <div className="pr-0 ml-6">
          <img
            src={boxSavorRR}
            className="hidden sm:block scale-130 mt-6"
            alt="Savor Box"
          />
        </div>
        <div className="flex-row md:ml-20 mt-8 w-full">
          <div className="basis-1/4 md:basis-1/4 align-middle text-left">
            <div className="welcome-text-main-navigation mt-3.5 text-sm">
              Welcome to the Savor Brands R+R Certification course.
            </div>
          </div>
          <div className="basis-1/4 md:basis-1/4 align-middle text-center mt-6 main-navigation-text">
            <p className="text-justify text-xs md:w-2/5">
              The Savor Brands R+R Program is part of our ongoing commitment to
              make sustainability easy and efficient for our partners and your
              customers. By reprocessing and repurposing the packaging we
              create, we are doing our part to help support the world we want to
              live in.{" "}
            </p>
            <p className="text-justify text-xs md:w-2/5 mt-4">
              {" "}
              This certification is made up of 6 modules and 6 mini quizzes.
              Each module includes a short video for each topic. Get started
              today:
            </p>
          </div>
          <div className="mt-16">
            <div className="flex flex-col mb-6">
              <h2 className="text-gray-600 font-extrabold text-sm mb-2">
                Modules
              </h2>
              <Progressbar percentage={completedPercentage}/>
            </div>
            <Modules
                modules={module.allContentfulModules.nodes}
                grades={grades}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
