import React from "react";
import Module from "./Module";
import "../../assets/styles/ModuleScroll.css";
import "../../assets/styles/Modules.css";

const Modules = ({ modules, grades }) => {
  const modulesToSend = modules.map((module) => {
    return {
      ...module,
      grade: grades.filter(
        (grade) => parseInt(grade.module_id) === parseInt(module.moduleId)
      ),
      gradeBefore: grades.filter(
        (grade) => parseInt(grade.module_id) === parseInt(module.moduleId) - 1
      ),
    };
  });

  return (
    <div
      className="scroll-horizontal flex sm:flex-no-wrap bg-white w-full md:w-full overflow-x-scroll"
      style={{ overflow: "auto" }}
    >
      <div className="flex flex-col sm:flex-no-wrap sm:flex-row w-full sm:w-full">
        {modulesToSend.map((module, index) => (
          <Module module={module} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Modules;
