import { Link } from "gatsby";
import React from "react";
import "../../assets/styles/Modules.css";

const Module = ({ module }) => {
  const { moduleId, name, image, module: isModule } = module;
  const approvedCurrent = module.grade.map((grade) => grade.approved);
  const approvedBefore = module.gradeBefore.map(
    (gradeBefore) => gradeBefore.approved
  );

  return moduleId === "1" || approvedCurrent[0] || approvedBefore[0] ? (
    <div className="relative sm:flex-none sm:w-1/4 md:w-1/4 p-3">
      <Link to={isModule ? "/module/" : "/assets/"} state={{ module: module }}>
        <img className="object-cover w-full h-64" src={image.url} alt={name} />
      </Link>

      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:left-2/5 sm:ml-3 w-full">
        <p className="mb-1 text-base font-semibold tracking-tight text-white text-center md:text-left ml-3">
          {name}
        </p>
      </div>
    </div>
  ) : (
    <div className="relative sm:flex-none sm:w-1/4 md:w-1/4 p-3 disabled">
      <Link to="#" state={{ module: module }}>
        <img className="object-cover w-full h-64" src={image.url} alt={name} />
      </Link>
      <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 md:left-2/5 sm:ml-3 w-full">
        <p className="mb-1 text-base font-semibold tracking-tight text-white text-center md:text-left ml-3">
          {name}
        </p>
      </div>
    </div>
  );
};

export default Module;
