import "../assets/styles/Modules.css";
import AppPage from "../components/AppPage";
import React from "react";
import { MainNavigationHeader } from "../components/main/MainNavigationHeader";
import MainPage from "../components/main/MainPage";
const MainNavigationPage = () => {
  return (
    <AppPage>
      <MainNavigationHeader />
      <MainPage />
    </AppPage>
  );
};
export default MainNavigationPage;
