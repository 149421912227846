import React from "react";
import { types } from "../../types/types";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { setToken } from "../../reducers/authSlice";
import { useDispatch } from "react-redux";
export const logoQuery = graphql`
  {
    allContentfulLogin {
      nodes {
        rrLogo {
          url
        }
      }
    }
  }
`;

export const MainNavigationHeader = () => {
  const data = useStaticQuery(logoQuery);
  const logoImg = data.allContentfulLogin.nodes[0].rrLogo.url;
  const dispatch = useDispatch();
  const handleSignOut = () => {
    dispatch(setToken(null));
    navigate("/", {
      replace: true,
    });
  };

  return (
    <div className="pb-10 sm:pb-28 mx-auto pl-4 sm:pl-20 pt-10">
      <div className="flex justify-between items-end">
        <button className="cursor-pointer" href="#" onClick={handleSignOut}>
          <span className="text-xs md:text-sm lg:text-base font-bold sign-out-text">
            Sign out
          </span>
        </button>
        <div className="text-xl font-semibold bg-r-r-container">
          <div className="">
            <a href="https://www.rplusrprogram.com/">
              <div className="block justify-center items-center">
                {logoImg ? (
                  <img
                    className="pr-20"
                    width={200}
                    src={logoImg}
                    alt="Savor Box"
                  />
                ) : (
                  ""
                )}
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
