import React from "react";

const Progressbar = ({percentage}) => {
    console.log('percentage');
    const progressbarHeight = 8;
    const progressbarWidth = 250;
    return (
        <div className="flex flex-col" style={{width:progressbarWidth}}>
            <div className="flex flex-row justify-between align-middle">
                <span className="text-xs">Progress</span>
                <span className="text-xs">{Math.round(percentage)}%</span>
            </div>
        <div style={{height:progressbarHeight,width: '100%', backgroundColor:'darkgray', borderRadius:5}}>
            <div style={{height:"100%",width: (percentage * progressbarWidth) / 100 , backgroundColor:'green', borderRadius:20}}/>
        </div>
        </div>
    );
}

export default Progressbar;
